<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="ColumnDefs" :externalFilter="filterByUserBerths"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'berthgrid',
	components: {},
	data() {
		return {
			modelName: 'berth',
			userBerthIds: [],
			ColumnDefs: {
				seaside: {
					render: (data, type, row, meta) => {
						if (data == 'L') {
							return this.$t('common.L');
						}
						return this.$t('common.R');
					}
				},
				hasgeom: {
					render: (data, type, row) => {
						return row.hasgeom ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	mounted() {
		this.userBerthIds = this.$store.getters.getUserBerthIds;
	},
	computed: {
		filterByUserBerths() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'id', op: 'in', data: this.userBerthIds }]
			};
		}
	}
};
</script>
